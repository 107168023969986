export class MergeResult {
    
    public numNewRecords:number = 0;
    public numNewColumns:number = 0;
    public numOverridenColumns:number = 0;
    public numOverridenRecords:number = 0;

    constructor() {
        
    }
}