import { BusinessType, DataTable, Field, FieldType, Record } from "@galigeo-store/shared-models";
import { MergeResult } from "./merge-result";

/**
 * Abstract class that defines the data fusion process
 */
export abstract class DataFusion {

    result = new MergeResult();
    newRecordIdx:Map<string, Record> = new Map();
    newDatafieldIndex:Map<string, number> = new Map();

    /**
     * Build the indexes for the new data :
     *  - newRecordIdx: Map of records by id
     *  - newDatafieldIndex: Map of field index by field name
     * @param newData 
     */
    public buildIndexes(newData: DataTable) {
        // 1. Build record index
        const fieldId = newData.getFieldByBusinessType(BusinessType.ID);
        if (!fieldId) return;
        const newIdIdx = newData.getFieldIndex(fieldId.name);
        for(let record of newData.data) {
            const idVal = record.values[newIdIdx];
            this.newRecordIdx.set(idVal, record);
        }

        // 2. Build field index
        for(let i=0;i<newData.fields.length;i++) {
            const field = newData.fields[i];
            this.newDatafieldIndex.set(field.name, i);
            
            // FIXME: New data is not renamed by business type, so we need to add the business type as well
            if(field.businessType) {
                this.newDatafieldIndex.set(field.businessType, i);
            } 
        }
    }

    /**
     * Add new records to the original data based on this.newRecordIdx
     * 
     * Warning: this method delete existing records to the index this.newRecordIdx
     * @param originalData 
     */
    public addNewRecords(originalData: DataTable) {
        
        // remove the records that are already in the original data
        const fieldId = originalData.getFieldByBusinessType(BusinessType.ID);
        const origIdIdx = originalData.getFieldIndex(fieldId.name);
        for(let record of originalData.data) {
            const id = record.values[origIdIdx] as string;
            this.newRecordIdx.delete(id);
        }

        // loop on the new records and add them to the original data
        for(let record of this.newRecordIdx.values()) {
            const recordToAdd = {recordId: record.recordId, values: new Array<any>(originalData.fields.length)} as Record;
            for(let i=0;i<originalData.fields.length;i++) {
                const originalField = originalData.fields[i];
                const newDataIdx = this.newDatafieldIndex.get(originalField.name);
                if(newDataIdx !== undefined) {
                    recordToAdd.values[i] = record.values[newDataIdx];
                    originalField.visible = true;
                }
            }
            this.result.numNewRecords++;
            originalData.data.push(recordToAdd);
        }
    }

    /**
     * Main method that tells if two fields are the same
     */
    public matchField(field1: Field, field2: Field): boolean {
        if(!field1 || !field2) return false;
        if(field1.businessType && field2.businessType && field1.businessType === field2.businessType) return true;
        if(field1.name?.toUpperCase() === field2.name?.toUpperCase()) return true;
        if(field1.alias === field2.alias) return true;
        return false;
    }

    /**
     * Given a list of fields, tell whether a field is in the list
     * @param fields 
     * @param field 
     * @returns 
     */
    public getMatchedField(fields: Field[], field:Field) {
        for(let f of fields) {
            if(this.matchField(f, field)) {
                return f;
            }
            
        }
        return null;
    }

    /**
     * Get a record by its id
     * 
     * @param dataTable 
     * @param id 
     */
    public getRecord(dataTable: DataTable, id: string): Record | null {
        const idField = dataTable.getFieldByBusinessType(BusinessType.ID);
        if(!idField) return null;
        const idIdx = dataTable.getFieldIndex(idField.name);
        for(let record of dataTable.data) {
            if(record.values[idIdx] === id) return record;
        }
        return null;
    }

    /**
     * Merge the new data into the original data
     * 
     * @param originalData 
     * @param newData 
     */
    public abstract mergeData(originalData: DataTable, newData: DataTable): MergeResult;
}