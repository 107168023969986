import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule, FileImportModule, IconModule, LocaleModule, MenuModule, ModalModule, StepperModule, SwitchModule, TableModule } from '@galigeo-store/ui-lightning';
import { SharedModule } from '../shared/shared.module';
import { NetworkContainerComponent } from './container/network.component';



@NgModule({
  declarations: [
    NetworkContainerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TableModule,
    DropdownModule,
    IconModule,
    FileImportModule,
    ModalModule,
    StepperModule,
    LocaleModule,
    SwitchModule,
    MenuModule,
  ]
})
export class NetworkModule { }
