import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { GlobalNavModule, LocaleModule, SpinnerModule } from '@galigeo-store/ui-lightning';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { HttpClientModule } from '@angular/common/http';
import { NetworkModule } from './network/network.module';
import { WizardModule } from './wizard/wizard.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GlobalNavModule,
    SpinnerModule,
    WizardModule,
    NetworkModule,
    HttpClientModule,
    LocaleModule
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
