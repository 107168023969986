<ui-lightning-modal [isOpen]="isOpen ? true : false"  (onClose)="closeNoGeoModal(false)">
  <ui-lightning-modal-header>
    <h2 class="slds-text-heading_medium"><ui-lightning-icon [iconName]="'utility:info_alt'" size="small"></ui-lightning-icon> {{'NetworkComponent:no_geom_warning_modal_title' | translate:'Important information' }}</h2>
  </ui-lightning-modal-header>
  <ui-lightning-modal-content>
    <div class="slds-form-element__control slds-grid slds-grid_align-space slds-grid_vertical-align-center">
      <div>
        <ui-lightning-icon variant="error" [iconName]="'utility:error'" size="medium"></ui-lightning-icon>
      </div>
      <div>
        {{'NetworkComponent:no_geom_warning_modal_text' | translate:'Not found adresses are not shown on the map, ' }}<strong>{{'NetworkComponent:no_geom_warning_modal_text_2' | translate:'edit the adresses to visualize them' }}</strong>
      </div>
    </div>
    <div class="slds-form-element" style="text-align: center; margin-top: 1rem;">
      <div class="slds-form-element__control">
        <div class="slds-checkbox">
        <input type="checkbox" name="options" [id]="'no_geom_' + rowId" [value]="'no_geom_' + rowId" />
        <label class="slds-checkbox__label" [for]="'no_geom_' + rowId">
          <span class="slds-checkbox_faux"></span>
          <span class="slds-form-element__label">{{'NetworkComponent:no_geom_warning_modal_checkbox' | translate:'Do not show again' }}</span>
        </label>
        </div>
      </div>
    </div>
  </ui-lightning-modal-content>
  <ui-lightning-modal-footer>
    <div class="slds-grid slds-grid_align-center">
      <button class="slds-button slds-button_brand" (click)="closeNoGeoModal(true)">{{'NetworkComponent:no_geom_warning_modal_btn' | translate:'Ok, understood' }}</button>
    </div>
  </ui-lightning-modal-footer>
</ui-lightning-modal>