import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthguardService } from '@galigeo-store/retail-services';

@Directive({
  selector: '[retailHasCapability]'
})
export class HasCapabilityDirective implements OnInit{
  @Input() retailHasCapability: string | undefined;

  constructor(private authguardService: AuthguardService,
    private el: ElementRef) {
      
      

   }
  ngOnInit(): void {
    if (this.retailHasCapability && !this.authguardService.hasCapability(this.retailHasCapability)) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
