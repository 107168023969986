import { BusinessType, DataTable, Field } from "@galigeo-store/shared-models";
import { DataFusion } from "./data-fusion";
import { MergeResult } from "./merge-result";

/**
 * Fusion process that adds only new fields and records
 */
export class AddOnly extends DataFusion {
    override mergeData(originalData: DataTable, newData: DataTable):MergeResult {
        
        this.buildIndexes(newData);

        // 1. Add new fields and store them in newColumnsByIdx
        const newColumnsByIdx:Map<number, Field> = new Map(); // map of new columns by index
        for(let field of newData.fields) {
            const originalField = this.getMatchedField(originalData.fields, field);
            const matchVisibility = originalField && originalField.visible === false && field.visible === true;
            if(!originalField) {
                const newFieldIdx = originalData.addField(field);
                newColumnsByIdx.set(newFieldIdx, field);
                this.result.numNewColumns++;                
            } else if (matchVisibility) {
                this.result.numNewColumns++;
                originalField.visible = true;
            }
        }

        // // 2. fill the new columns (from newColumnsByIdx)
        // const fieldId = originalData.getFieldByBusinessType(BusinessType.ID);
        // const origIdIdx = originalData.getFieldIndex(fieldId.name);
        // for(let record of originalData.data) {
        //     const id = record.values[origIdIdx];
        //     const newRecord = this.getRecord(newData, id);
        //     if(newRecord) {
        //         for(let colIdx of newColumnsByIdx.keys()) {
        //             const field = newColumnsByIdx.get(colIdx);
        //             if(!field) continue;
        //             const newDataIdx = this.newDatafieldIndex.get(field.name);
        //             if(newDataIdx === undefined) continue;
        //             record.values[colIdx] = newRecord.values[newDataIdx];
        //         }
        //     }
        // }

        // 3. Add new records
        this.addNewRecords(originalData);

        return this.result;
    }
    
}