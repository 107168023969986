import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { BusinessType, DataTable, Field, FieldType, DataType } from '@galigeo-store/shared-models';
import {  LanguageService } from '@galigeo-store/ui-lightning';
import { LocaleMappingFieldsComponent } from '../locales/localeMappingFieldsComponent';

@Component({
  selector: 'retail-mapping-fields',
  templateUrl: './mapping-fields.component.html',
  styleUrls: ['./mapping-fields.component.css']
})
export class MappingFieldsComponent implements OnInit {
  @Input() businessFields!: any[];
  @Input() fields!: any[];
  @Input() dataTable!: DataTable;
  @Input() identifyFields!: boolean;
  @Output() changeEvent = new EventEmitter<boolean>();
  @Output() changeEventBT = new EventEmitter<boolean>();
  @Output() changeBusinessType = new EventEmitter<boolean>();
  @Output() changeBusinessTypeFields = new EventEmitter<Field[]>();
  @Output() changeBusinessFields = new EventEmitter<any>();
  @Output() hasCityAndPostalCode = new EventEmitter<boolean>();
  public fieldsToDelete: Field[] = [];
  public visibleFields: Field[] = [];
  public duplicateLine?: string;
  public originalDataTable!: DataTable;

  @ViewChildren('selectBusinessType') selectBT!: QueryList<ElementRef>;

  constructor(private languageService: LanguageService) {
    this.languageService.addTranslation('MappingFieldsComponent', new LocaleMappingFieldsComponent().locale);
  }

  ngAfterViewInit() {
    this.selectBT.changes.subscribe(() => {
      console.log(this.selectBT);
    });
  }
  ngOnInit(): void {
     this.originalDataTable = this.dataTable.clone();

    const hasAnyBusinessType = this.dataTable?.fields?.some((field: any) => field.businessType);

    // create the aliases and set the visibilty
    this.dataTable?.fields.forEach((field: any) => {
      if (!field.alias) {
          field.alias = field.name;
      }
  
      if (field.visible) {
          this.visibleFields.push(field);
      }
  
      if (!hasAnyBusinessType || field.businessType === undefined) {
          // Try to guess the business type
          const nameLower = field.name.toLowerCase();
          switch (nameLower) {
              case 'id':
              case 'identifier':
                  field.businessType = BusinessType.ID;
                  break;
              case 'nom':
              case 'name':
                  field.businessType = BusinessType.NAME;
                  break;
              case 'x':
              case 'gpsx':
              case 'longitude':
              case 'long':
              case 'x_long':
                  field.businessType = BusinessType.LONG;
                  break;
              case 'y':
              case 'gpsy':
              case 'latitude':
              case 'lat':
              case 'y_lat':
                  field.businessType = BusinessType.LAT;
                  break;
              case 'address':
              case 'adresse':
                  field.businessType = BusinessType.ADDRESS;
                  break;
              case 'commune':
              case 'city':
              case 'ville':
                  field.businessType = BusinessType.CITY;
                  break;
              case 'cp':
              case 'code postal':
              case 'code_postal':
              case 'postal_code':
                  field.businessType = BusinessType.POSTAL_CODE;
                  break;
          }
      }
  
      const businessField = !this.identifyFields ? this.getBusinessField(field.businessType) : this.getFieldByBusinessType(field.businessType);

      field.visible = businessField ? businessField.visible : true
      if(field.businessType === undefined || field.businessType === null || field.businessType === '') {
        switch (field.type) {
          case FieldType.STRING:
            field.businessType = 'type_string';
            break;
          case FieldType.BOOLEAN:
            field.businessType = 'type_boolean';
            break;
          case FieldType.INTEGER:
            field.businessType = 'type_integer';
            break;
          case FieldType.DOUBLE:
            field.businessType = 'type_double';
            break;
          case FieldType.DATE:
            field.businessType = 'type_date';
            break;
        }
      }
      this.onChangeSelectCheck();
      // All fields are selected by default
      field.selected = true;
    });
  }
  
  private getBusinessField(businessType: string) {
      return this.businessFields.find((f: any) => f.businessType === businessType);
  }
  
  private getFieldByBusinessType(businessType: string) {
      return this.fields.find((f: any) => f.businessType === businessType);
  }
  
  onChangeSelectCheck(changedField?: Field, newType?: any) {
    const duplicateFields: Set<string> = new Set();
    const firstTime: Set<string> = new Set();
  
    // Loop through each field in the dataTable
    if(changedField) {
      this.convertTypes(changedField, newType);
    }else {
    for (const field of this.dataTable.fields) {
      // If the businessType is not null and has already been encountered
      if (field.businessType && firstTime.has(field.businessType)) {
        duplicateFields.add(field.name);
      } else if (field.businessType && !field.businessType.startsWith('type_')) {
        // Add the businessType to the Set to mark it as encountered
        firstTime.add(field.businessType);
      }
    }
      this.convertTypes();
    // Emit events based on certain conditions
    }

    
    this.changeEventBT.emit(duplicateFields.size > 0);
    this.checkHasAddressLatLongCityAndCodePostal();
    this.duplicateLine = duplicateFields.size > 0
      ? `${this.languageService.getTranslation('MappingFieldsComponent', 'step_select_columns_duplicate')}`
      : undefined;
  }

  wasVisible(fieldToCheck: Field): boolean {
    return this.visibleFields.length === 0 || this.visibleFields.some(({ name }) => name === fieldToCheck.name);
  }

  private checkHasAddressLatLongCityAndCodePostal() {
    const hasAddress = this.dataTable.fields.some((f: any) => f.businessType === BusinessType.ADDRESS);
    const plannedToDeleteAddress = this.fieldsToDelete.some(f => f.businessType === BusinessType.ADDRESS);
    const hasLat = this.dataTable.fields.some(f => f.businessType === BusinessType.LAT);
    const plannedToDeleteLat = this.fieldsToDelete.some(f => f.businessType === BusinessType.LAT);
    const hasLong = this.dataTable.fields.some(f => f.businessType === BusinessType.LONG);
    const plannedToDeleteLong = this.fieldsToDelete.some(f => f.businessType === BusinessType.LONG);
    const hasCity = this.dataTable.fields.some(f => f.businessType === BusinessType.CITY);
    const plannedToDeleteCity = this.fieldsToDelete.some(f => f.businessType === BusinessType.CITY);
    const hasPostalCode = this.dataTable.fields.some(f => f.businessType === BusinessType.POSTAL_CODE);
    const plannedToDeletePostalCode = this.fieldsToDelete.some(f => f.businessType === BusinessType.POSTAL_CODE);

    this.changeEvent.emit((hasAddress && !plannedToDeleteAddress) || (hasLat && hasLong && !plannedToDeleteLat && !plannedToDeleteLong));
    this.hasCityAndPostalCode.emit((hasLat && hasLong && !plannedToDeleteLat && !plannedToDeleteLong) || ((hasCity && hasPostalCode && !plannedToDeleteCity && !plannedToDeletePostalCode) || (!hasAddress || plannedToDeleteAddress)));
  }

  onChangeSelect(field: Field) {
    if (field.selected) {
      this.fieldsToDelete = this.fieldsToDelete.filter((f: Field) => f.name !== field.name);
      //Marche plus faudra que je creuse
      //field.visible = true;
    } else {
      this.fieldsToDelete.push(field);
      //Marche plus faudra que je creuse
      //field.visible = false;
    }
    this.checkHasAddressLatLongCityAndCodePostal();
    this.changeBusinessTypeFields.emit(this.fieldsToDelete);
  }
  private convertTypes(changedField?: Field, newType?: string): void {
    if(changedField && newType) {
      const businessField: Field = this.businessFields.find((businessField) => businessField.businessType === newType);
        if (businessField) {
          changedField.type = businessField.type;
          changedField.businessType = businessField.businessType;
            this.convertFieldValues( this.dataTable.getFieldIndex(changedField.name), businessField.type ?? FieldType.STRING);
        }else{
          switch (newType) {
            case 'type_string':
              changedField.type = FieldType.STRING;
              break;
            case 'type_boolean':
              changedField.type = FieldType.BOOLEAN;
              break;
            case 'type_integer':
              changedField.type = FieldType.INTEGER;
              break;
            case 'type_double':
              changedField.type = FieldType.DOUBLE;
              break;
            case 'type_date':
              changedField.type = FieldType.DATE;
              break;
          }
          changedField.businessType = newType;
          this.convertFieldValues( this.dataTable.getFieldIndex(changedField.name), changedField.type ?? FieldType.STRING);
        }
    }else {
      for (let i = 0; i < this.dataTable.fields.length; i++) {
        const field = this.dataTable.fields[i];
        // business type ?
        const businessField: Field = this.businessFields.find((businessField) => businessField.businessType === field.businessType);
        if (businessField) {
            field.type = businessField.type;
            this.convertFieldValues( i, businessField.type ?? FieldType.STRING);
        }
    }
    }
  }

  private convertFieldValues(index: number, newType: FieldType): void {
    for (let i = 0 ; i <  this.originalDataTable.data.length; i++) {
        const value = this.originalDataTable.data[i].values[index];

        switch (newType) {
            case FieldType.BOOLEAN:
              this.dataTable.data[i].values[index] = DataType.convertToBoolean(value);
              break;
            case FieldType.INTEGER:
              
               this.dataTable.data[i].values[index] = DataType.convertToInteger(value);
              break;
            case FieldType.DOUBLE:
               this.dataTable.data[i].values[index] = DataType.convertToDouble(value,newType);
              break;
            case FieldType.DATE:
              this.dataTable.data[i].values[index] =  DataType.convertToDate(value);
              break;
            case FieldType.STRING:
              this.dataTable.data[i].values[index] = DataType.convertToString(value);
              break;
            default:
        }
    }
  }
  preselectedBusinessType(fiBusinessType: any, businessField: any, fieldIndex: any) {
    if (fiBusinessType === businessField) {
      const selectBTElement = this.selectBT?.toArray()[fieldIndex]?.nativeElement;
      if (selectBTElement && selectBTElement.style.fontWeight != 500) {
        selectBTElement.style.background = '#e8fff2';
        selectBTElement.style.fontWeight = '500';
      }
      return true;
    } else {
      return false;
    }
  }
}
