
<span *ngIf="this.duplicateLine" class="slds-icon_container slds-text-color_error" title="Warning">
  <svg class="slds-icon slds-icon_xx-small slds-icon-text-warning" aria-hidden="true">
      <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#warning"></use>
  </svg>
  {{this.duplicateLine}}
</span>
<div class="slds-grid slds-grid_vertical identifyColumnsContainer">
  <div class="slds-grid identifyColumnsText">
    <div class="slds-col slds-size_1-of-2">
      <span class="title-cols" title="{{'MappingFieldsComponent:step_select_columns_col_file' | translate:'File columns' }}">{{'MappingFieldsComponent:step_select_columns_col_file' | translate:'File columns' }}</span>
    </div>
    <div class="slds-col slds-size_1-of-2">
      <span class="title-cols" title="{{'MappingFieldsComponent:step_select_columns_col_values' | translate:'Corresponding value' }}">{{'MappingFieldsComponent:step_select_columns_col_values' | translate:'Corresponding value'}}</span>
    </div>
    
  </div>
    <div class="slds-grow slds-scrollable_y columnsContainer">
      <ng-template ngFor let-field [ngForOf]="dataTable.fields" let-i="index">
      <div *ngIf="wasVisible(field)" class="slds-grid slds-p-around_xx-small">
        <div class="slds-col slds-size_1-of-2">
          <div class="slds-grid">
            <div class="slds-col slds-truncate">
              <div class="slds-form-element">
                <div class="slds-form-element__control slds-truncate">
                  <div class="slds-checkbox slds-truncate">
                    <input type="checkbox" [(ngModel)]="field.selected" name="{{field.name}}" id="{{field.name}}-{{i}}" (change)="onChangeSelect(field)"/>
                    <label class="slds-checkbox__label" for="{{field.name}}-{{i}}">
                      <span class="slds-checkbox_faux"></span>
                      <span class="slds-form-element__label">{{field.alias}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
          
          
        </div>
        <div class="slds-col slds-size_1-of-2">
          <div class="slds-form-element" style="display: block;">
            <div class="slds-form-element__control">
              <div class="slds-select_container">
                <select #selectBusinessType class="slds-select" id="select-mapping-{{i}}" (ngModelChange)="onChangeSelectCheck(field, $event)" [(ngModel)]="field.businessType">
                  <option [ngValue]="undefined" disabled selected hidden>{{'MappingFieldsComponent:step_select_columns_choise_field' | translate:'Select a value' }}</option>
                  <option [ngValue]="null"></option>
                  <ng-template ngFor let-fi [ngForOf]="businessFields" let-j="index">
                    <option *ngIf="fi.visible" [selected]="preselectedBusinessType(fi['businessType'], field.businessType, i)" [ngValue]="fi['businessType']" >{{fi['alias']}} </option>
                  </ng-template>
                  <option disabled>_________</option>
                  <option [ngValue]="'type_string'">{{'MappingFieldsComponent:step_select_columns_choise_field_text' | translate:'Text' }}</option>
                  <option [ngValue]="'type_date'">{{'MappingFieldsComponent:step_select_columns_choise_field_date' | translate:'Date' }}</option>
                  <option [ngValue]="'type_integer'">{{'MappingFieldsComponent:step_select_columns_choise_field_number' | translate:'Integer' }}</option>
                  <option [ngValue]="'type_double'">{{'MappingFieldsComponent:step_select_columns_choise_field_double' | translate:'Double' }}</option>
                  <option [ngValue]="'type_boolean'">{{'MappingFieldsComponent:step_select_columns_choise_field_boolean' | translate:'Boolean' }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      </ng-template>
      
    </div>
  </div>