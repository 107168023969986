import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { GeocodeService } from '@galigeo-store/retail-services';
import { BusinessType, DataTable, MessageOptions, PROGRESS_TYPE, ProgressOptions, Record } from '@galigeo-store/shared-models';
import { LanguageService, MessageService, ModalBase } from '@galigeo-store/ui-lightning';
import { LocaleModalAdresseComponent } from '../locales/localeModalAdresseComponent';
import { Network } from '../models/network';
@Component({
  selector: 'retail-modal-no-geom-warning',
  templateUrl: './modal-no-geom-warning.component.html',
  styleUrls: ['./modal-no-geom-warning.component.css']
})
export class ModalNoGeomWarningComponent extends ModalBase {
  isOpen: boolean = false;
  rowId!: any;
  
  constructor(private host: ElementRef<HTMLElement>, private languageService: LanguageService,
    private messageService: MessageService) { 
    super();
    this.languageService.addTranslation('ModalAdresseComponent', new LocaleModalAdresseComponent().locale);
  }

  async open() {
    if (!localStorage.getItem('dontShowGeoWarn')) {
      this.isOpen = true;
    }
  }

  closeNoGeoModal(useBtn: boolean) {
    // get checkbox value
    const checkboxValue = (document.getElementById('no_geom_' + this.rowId) as HTMLInputElement).checked;
  
    if (useBtn && checkboxValue) {
      localStorage.setItem('dontShowGeoWarn', 'true');
    }
    this.isOpen = false;
  }
}
