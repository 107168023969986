import { Locale } from '@galigeo-store/ui-lightning';

export class LocaleWizardUpdateComponent extends Locale {
  constructor() {
    super();
    this.languages = {
      en: {
        upload_a_csv: 'Upload a CSV or XLSX file',
        step_add_file_title: 'Update Network',
        step_add_file_label: 'Update Network',
        step_add_file_description: 'CSV and XLSX formats files only',
        step_select_columns_info: 'Select the same identifier to update the network',
        step_select_columns_title: 'Identify the columns',
        step_select_columns_label: 'Identify the columns',
        step_select_columns_description:  'Identify at least the address, or Lat/Long columns',
        step_merge_title: 'Data fusion',
        step_merge_label: 'Data fusion',
        step_merge_description: 'How do you want to merge the data?',
        step_merge_first_choice_title: 'Add only',
        step_merge_first_choice_label_add: 'line(s) will be added.',
        step_merge_second_choice_title: 'Add and update',
        step_merge_second_choice_label_add: 'line(s) will be added,',
        step_merge_second_choice_label_update: 'line(s) will be updated',
        step_merge_third_choice_title: 'Replace all',
        step_merge_third_choice_label_info: 'The current network will be replaced by the new one imported. All the previous data will be lost.',
        step_add_file_view_file: 'File Preview',
        global_spinner_message: 'Loading...',
        step_merge_first_choice_label_add_column: 'new column(s) and',
        step_merge_second_choice_label_add_column: 'new column(s) will be added',
        confirm_modal_title: 'Important Information',
        alert_title_inconsistencies_error: 'Unable to continue',
      },
      fr: {
        upload_a_csv: 'Importer un CSV ou XLSX',
        step_add_file_title: 'Mettre à jour le réseau',
        step_add_file_label: 'Mettre à jour le réseau',
        step_add_file_description: 'Fichiers format CSV et XLSX uniquement',
        step_select_columns_info: 'Sélectionner le même identifiant pour mettre à jour le réseau.',
        step_select_columns_title: 'Identifier les colonnes',
        step_select_columns_label: 'Identifier les colonnes',
        step_select_columns_description:  'Identifier au minimum les colonnes d\'adresse ou Lat, Long',
        step_merge_title: 'Fusion des données',
        step_merge_label: 'Fusion des données',
        step_merge_description: 'Comment souhaitez-vous fusionner les données ?',
        step_merge_first_choice_title: 'Ajouter uniquement',
        step_merge_first_choice_label_add: 'ligne(s) seront ajoutées.',
        step_merge_second_choice_title: 'Ajouter et mettre à jour',
        step_merge_second_choice_label_add: 'ligne(s) seront ajoutées,',
        step_merge_second_choice_label_update: 'ligne(s) seront mis à jour,',
        step_merge_third_choice_title: 'Remplacer tout',
        step_merge_third_choice_label_info: 'Le réseau actuel sera remplacé par le nouveau importé. Toutes les données précédentes seront perdues.',
        step_add_file_view_file: 'Aperçu du fichier',
        global_spinner_message: 'Chargement...',
        step_merge_first_choice_label_add_column: 'nouvelle(s) colonne(s) et',
        step_merge_second_choice_label_add_column: 'nouvelle(s) colonne(s) seront ajoutées',
        confirm_modal_title: 'Information importante',
        alert_title_inconsistencies_error: 'Impossible de continuer',
      },
    };
    this.genrateMap();
  }
}
