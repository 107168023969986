import { DataTable } from "@galigeo-store/shared-models";

export class Network {
    
    constructor(data?: any) {
        if(data) {
            this.id = data.id;
            this.logo = data.logo;
            this.name = data.name;
            this.label = data.label;
            this.description = data.description;
            this.pois = data.pois;
            this.fileName = data.fileName;
            this.isDefault = data.is_default;
        }
    }

    id?: number;
    logo?: string;
    name?: string;
    label?: string;
    description?: string;
    pois?: DataTable;
    fileName?: string;
    isDefault?: boolean;
}